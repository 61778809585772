<script src="../../../routes/goods/index.js"></script>
<template>
  <div>
    <b-card class="mb-2">
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="발란코드, 파트너상품코드, 상품명, SKU, 통합 SKU, 시즌, 원산지, 상품관리코드, 상세설명" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
      </b-input-group>

      <form-options v-model="form.list" v-bind="{formOptions}" class="mb-2">
        <template v-slot:slot1>
          <div class="flex-grow-0 mb-1 mr-2">
            <small>Color 선택</small><br/>
            <color-checkbox v-model="form.list.major_color"></color-checkbox>
          </div>
          <div class="flex-grow-0 mb-1 mr-2">
            <small>기본 상품수</small><br/>
            <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
          </div>
        </template>
      </form-options>

      <b-collapse id="collapseES" v-model="collapse.detail">
        <b-row>
          <b-col class="mb-1" cols="12" md="6">
            <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col class="mb-1" cols="12" md="6">
            <category-preset class="" v-model="form.list.category"></category-preset>
          </b-col>
        </b-row>

        <div v-if="!IS_DEV">
          <div class="fs-12 bold"> 검색 필드</div>
          <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                       v-bind="{formFields, defaultFields, statUri: '/goods/confirmed/fieldStat'}" @enter="list()"></form-fields>
        </div>
        <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                      v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>
      </b-collapse>

      <div class="mt-2">
        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
        <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
        <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseES>상세검색조건</b-button>
      </div>
    </b-card>

    <master-confirmed-modal ref="masterConfirmedModal" v-bind="{modal, shopMap, targetMap, gmStatusKrMap}"></master-confirmed-modal>

    <image-modal ref="imageModal" v-bind="{modal}"></image-modal>

    <b-modal title="마스터 임시 매칭" size="lg" v-model="modal.matchMaster" @ok="matchMaster">
      <b-alert show variant="info">
        총 {{items.list.filter(e=>e.selected).length}} 건에 대해 마스터를 임시 매칭합니다.<br />
      </b-alert>
      <b-alert v-if="items.list.filter(e => e.selected).some(e => e.gm_status)" show variant="danger">
        보류 상태이거나 이미 마스터가 매칭된 상품은 마스터를 매칭할 수 없습니다.<br />
        매칭 대상: {{items.list.filter(e=>e.selected && !e.gm_status).length}}건 / 임시 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'temp_matched').length}}건 / 확정 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'confirmed').length}}건<br />
        5분 실패: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_five_minutes').length}}건 / 모듬상품: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_multioption').length}}건 / 단일파트너: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_single_partner').length}}건<br />
        브랜드 오류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_wrong_brand').length}}건 / 기타 보류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_etc').length}}건
      </b-alert>

      <label>매칭할 마스터 ID<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="matching.gm_id"></b-input>
    </b-modal>

    <b-modal title="마스터 매칭 해제" size="lg" v-model="modal.removeMatching" @ok="removeMatching">
      <b-alert show variant="info">
        총 {{items.list.filter(e=>e.selected).length}} 건의 마스터 매칭을 해제합니다.<br />
      </b-alert>
      <b-alert v-if="items.list.filter(e => e.selected).some(e => !['temp_matched', 'confirmed'].includes(e.gm_status))" show variant="danger">
        매칭 상태가 아닌 상품은 매칭을 해제할 수 없습니다.<br />
        매칭 대상: {{items.list.filter(e=>e.selected && !e.gm_status).length}}건 / 임시 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'temp_matched').length}}건 / 확정 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'confirmed').length}}건<br />
        5분 실패: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_five_minutes').length}}건 / 모듬상품: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_multioption').length}}건 / 단일파트너: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_single_partner').length}}건<br />
        브랜드 오류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_wrong_brand').length}}건 / 기타 보류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_etc').length}}건
      </b-alert>

      <label>매칭 해제 사유<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="matching.gm_status_reason"></b-input>
    </b-modal>

    <b-modal title="매칭 보류 처리" size="lg" v-model="modal.excludeMatching" @ok="excludeMatching">
      <b-alert show variant="info">
        총 {{items.list.filter(e=>e.selected).length}} 건을 매칭 보류처리합니다.<br />
      </b-alert>
      <b-alert v-if="items.list.filter(e => e.selected).some(e => ['temp_matched', 'confirmed'].includes(e.gm_status))" show variant="danger">
        이미 마스터가 매칭된 상품은 보류 처리할 수 없습니다.<br />
        매칭 대상: {{items.list.filter(e=>e.selected && !e.gm_status).length}}건 / 임시 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'temp_matched').length}}건 / 확정 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'confirmed').length}}건<br />
        5분 실패: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_five_minutes').length}}건 / 모듬상품: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_multioption').length}}건 / 단일파트너: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_single_partner').length}}건<br />
        브랜드 오류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_wrong_brand').length}}건 / 기타 보류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_etc').length}}건
      </b-alert>

      <label>보류 유형<span class="text-danger">*</span></label>
      <b-form-radio-group class="col-form-label" v-model="matching.excludeType" :options="[
        {text: '5분 실패', value: 'excl_five_minutes'},
        {text: '모듬상품', value: 'excl_multioption'},
        {text: '단일 파트너', value: 'excl_single_partner'},
        {text: '브랜드 오류', value: 'excl_wrong_brand'},
        {text: '기타', value: 'excl_etc'},
      ]"></b-form-radio-group>
      <br/>
      <label>보류 처리 사유<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="matching.gm_status_reason"></b-input>
    </b-modal>

    <b-modal title="보류 해제 처리" size="lg" v-model="modal.rollbackExclude" @ok="rollbackExclude">
      <b-alert show variant="info">
        총 {{items.list.filter(e=>e.selected).length}} 건을 보류 해제합니다.<br />
      </b-alert>
      <b-alert v-if="items.list.filter(e => e.selected).some(e => !(e.gm_status || '').startsWith('excl'))" show variant="danger">
        현재 매칭 보류 상태가 아닌 상품은 보류 해제할 수 없습니다.<br />
        매칭 대상: {{items.list.filter(e=>e.selected && !e.gm_status).length}}건 / 임시 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'temp_matched').length}}건 / 확정 매칭: {{items.list.filter(e=>e.selected && e.gm_status === 'confirmed').length}}건<br />
        5분 실패: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_five_minutes').length}}건 / 모듬상품: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_multioption').length}}건 / 단일파트너: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_single_partner').length}}건<br />
        브랜드 오류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_wrong_brand').length}}건 / 기타 보류: {{items.list.filter(e=>e.selected && e.gm_status === 'excl_etc').length}}건
      </b-alert>

      <label>보류 해제 사유<span class="text-danger">*</span></label>
      <b-input autocomplete="off" v-model="matching.gm_status_reason"></b-input>
    </b-modal>

    <div ref="toolbar" class="clearfix">
      <div v-if="$R('SKU_INHOUSE')" class="pull-left">
        <b-button class="m-1" variant="primary" @click="showMasterMatchingModal">마스터 임시 매칭</b-button>
        <b-button class="m-1" variant="success" @click="confirmMatching">매칭 확정</b-button>
        <b-button class="m-1" variant="danger" @click="showRemoveMatchingModal">매칭 해제</b-button>
        <b-button class="m-1" variant="dark" @click="showExcludeMatchingModal">매칭 보류</b-button>
        <b-button class="m-1" variant="light" @click="showRollbackExcludeModal">보류 해제</b-button>
      </div>
      <div class="pull-right">
        <b-dropdown right variant="light" class="m-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
          <b-dropdown-item @click="copy('gm_id')">마스터 ID(매칭된 것만)</b-dropdown-item>
          <!--          <b-dropdown-item @click="copy('goods_id', {withQuotes: true})">Goods ID(따옴표)</b-dropdown-item>-->
        </b-dropdown>
        <b-dropdown variant="success" class="m-1" :disabled="busy.xlsxDown">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxDown"></b-spinner>
            Xlsx Down
          </template>
          <b-dropdown-item @click="preDown('')">기본 상품 형식</b-dropdown-item>
          <b-dropdown-item @click="preDown('global-upload')">글로벌 정보 업로드 형식</b-dropdown-item>
        </b-dropdown>
<!--        <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsxItems" :labels="xlsx.labels" :keys="xlsx.keys" name="MasterMatching"></xlsx>-->
        <b-dropdown variant="outline-success" class="m-1" :disabled="busy.xlsxUp">
          <template v-slot:button-content>
            <b-spinner class="mr-2" small v-if="busy.xlsxUp"></b-spinner>
            Xlsx Upload
          </template>
          <b-dropdown-item @click="upXlsx('global-upload')">글로벌 정보 업로드</b-dropdown-item>
        </b-dropdown>

        <iframe name="xlsx_frame" style="display:none"></iframe>
        <form :action="getHost() + '/data/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
          <input ref="json_data" type="hidden" name="j" />
        </form>

        <input type="file" ref="xlsx-global-upload" data-type="global-upload" style="display: none" @change="handleXlsx">
      </div>
    </div>

    <div class="bg-white rounded p-2">
      <div class="clearfix">
        <div class="pull-right">
          <b-button variant="primary" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = true);recalcPicFilteredCnt();$forceUpdate()}">전체선택</b-button>
          <b-button variant="warning" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = false);recalcPicFilteredCnt();$forceUpdate()}">선택해제</b-button>
        </div>
        <b-form inline>
          <b-button-group>
            <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
              {{items.list.length}}{{hasMore.list && !total.list ? '+' : ''}} 개
              {{total.list ? ` (총 ${total.list.value.comma()}${total.list.relation === 'gte' ? '+' : ''} 개)` : ''}}
            </b-button>
            <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
              {{items.list.filter(e=>e.selected).length}} 개 선택된 아이템
            </b-button>
          </b-button-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-filter"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="picFilter"></b-form-input>
            <b-input-group-append v-if="picFilter">
              <b-input-group-text>
                {{picFilteredCnt}} 개
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend><b-input-group-text>이미지 너비</b-input-group-text></b-input-group-prepend>
            <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
            <b-input-group-append><b-input-group-text>px</b-input-group-text></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=175">175</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=195">195</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=225">225</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=265">265</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=318">318</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=400">400</b-button></b-input-group-append>
          </b-input-group>
          <b-checkbox class="ml-2" v-model="picInfoTop">상단정보</b-checkbox>
          <b-checkbox class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
        </b-form>
      </div>
      <drag-select ref="drag" class="flex-row flex-wrap d-flex" attribute="attr" selectorClass="itemToBeSelected" @change="dragSelectItems">
        <template v-for="e of items.list">
          <div v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
             :key="e.goods_no" :attr="e.goods_no" :selected="e.selected ? 1 : 0"
             class="flex-grow-0 m-1 position-relative itemToBeSelected"
             :style="{width:picWidth+'px', padding:'3px', border:e.selected ? '3px solid #20a8d8' : '3px solid #f8f8f8'}"
             @click="clickItem($event, e)">
            <div class="position-absolute text-right" style="right:0;line-height:15px">
              <b-badge class="pointer mr-1" variant="info" size="sm" @click.prevent.stop="showImageModal(e)">이미지 보기<i class="fa fa-external-link"></i></b-badge>
              <b-badge class="ml-1 pointer" size="sm" @click.prevent.stop="showModal({item: e})">상세</b-badge>
              <div v-if="picInfoTop">
                <template v-if="e.matched_sku_id">
                  <b-badge variant="info" class="pointer" @click.prevent.stop="copyOne(e.matched_sku_id)"><i class="fa fa-copy"></i> {{e.matched_sku_id}}</b-badge><br/>
                </template>
                <template v-else>
                  <b-badge variant="dark">통합 SKU 없음</b-badge><br/>
                </template>
                <template v-if="e.short_sku">
                  <b-badge variant="light" class="pointer" @click.prevent.stop="copyOne(e.short_sku)"><i class="fa fa-copy"></i> {{e.short_sku}}</b-badge><br/>
                </template>
                <template v-else>
                  <b-badge variant="dark">Short SKU 없음</b-badge><br/>
                </template>
                <template v-if="e.gm_id">
                  <b-badge :variant="{'temp_matched': 'warning', 'confirmed': 'primary'}[e.gm_status] || 'dark'" class="pointer" @click.prevent.stop="copyOne(e.gm_id)"><i class="fa fa-copy"></i> {{e.gm_id}}</b-badge><br/>
                </template>
                <b-badge :variant="{'temp_matched': 'warning', 'confirmed': 'primary'}[e.gm_status] || 'dark'">{{gmStatusKrMap[e.gm_status] || '매칭 대상'}}</b-badge><br/>
              </div>
            </div>
            <img :src="e.img_urls[0]" @dragstart.prevent="" :data-goods_no="e.goods_no" @mouseover="rotateImage($event, e)" @mouseout="stopRotate($event, e)" class="w-100"/>
            <!-- 하단 정보 -->
            <div v-if="picInfoBottom" class="info-bottom mb-2" style="line-height:18px">
              <span v-html="makeBrandBadge(e.brand_no, e.brand_nm, {type: brandMap[e.brand_no].brand_type})"></span>
              <!--            <b-badge variant="warning">{{ e.brand_no }}. {{ e.brand_nm }}</b-badge>-->
              <br/>
              <b-badge class="text-truncate overflow-hidden mt-1 text-left" variant="info" style="max-width:100%;width:fit-content;">{{e.cate}}</b-badge><br/>
              <b-badge v-if="e.goodsType !== 'new'" variant="danger">U</b-badge>
              <b-badge variant="success">{{e.goods_no}}</b-badge><br/>
              <div class="text-truncate overflow-hidden fs-11 bold" :title="e.goods_nm">{{e.goods_nm}}</div>
              <b-badge :title="e.goods_nm_en">{{e.goods_nm_en}}</b-badge>
              <b-badge>{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
              <b-badge v-for="(opt, idx) of e.options.filter(e => !e.not_found)" variant="light" :key="idx">{{opt.Size}}</b-badge>
            </div>
          </div>
        </template>
      </drag-select>
    </div>
    <div v-if="hasMore.list" class="text-center py-3">
      <b-button variant="primary" size="lg" @click="list(true)">더 가져오기</b-button>
    </div>
    <div @click="scrollTo('toolbar')" class="text-right pointer" style="bottom: 1rem; position: fixed; right: 2rem; width: fit-content; z-index: 10;">
      <b-button pill variant="outline-secondary">Top<i class="ml-1 fa fa-arrow-up"></i></b-button>
    </div>
  </div>
</template>

<script>
import masterConfirmedModal from '@/views/master/MasterConfirmedModal.vue'
import imageModal from '@/views/master/ImageModal.vue'
import xlsx from '@/views/modules/Xlsx.vue'
import DragSelect from '@/views/modules/DragSelect.vue'
import {MASTER_MATCHING_COLUMNS} from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import FormFields from "../modules/FormFields";
import FormOptions from "../modules/FormOptions";
import FormIncExc from "../modules/FormIncExc";
import ColorCheckbox from "../modules/ColorCheckbox";
import {formOptionsPreset} from "@/shared/fields";
import Vue from "vue";
import ListDataMixin from '../modules/ListDataMixin'
import * as utils from "@/shared/utils";
import {readXlsx} from "@/shared/impexp";
import {getHost} from '@/shared/api'


const targetMap = { // 상품링크 매칭에 사용됨
  godo: '고도몰',
  lotteimall: '롯데아이몰',
  smartstore: '스토어팜(발란)',
};
const metaGmStatus = [
  {text: '매칭 대상', value: 'not_matched'},
  {text: '임시 매칭', value: 'temp_matched'},
  {text: '확정 매칭', value: 'confirmed'},
  {text: '[보류]5분 초과', value: 'excl_five_minutes'},
  {text: '[보류]모듬상품', value: 'excl_multioption'},
  {text: '[보류]단일 파트너', value: 'excl_single_partner'},
  {text: '[보류]브랜드 오류', value: 'excl_wrong_brand'},
  {text: '[보류]기타', value: 'excl_etc'}
];

export default {
  name: 'MasterMatching',
  title: '마스터SKU 매칭',
  mixins: [
    ListDataMixin
  ],
  components: {FormFields, FormOptions, FormIncExc, ColorCheckbox, xlsx, masterConfirmedModal, imageModal, DragSelect},
  data() {
    return {
      getHost,
      MASTER_MATCHING_COLUMNS, metaGmStatus,
      targetMap,
      is_dev: this.IS_DEV,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      gmStatusKrMap: this.$utils.arr2map(metaGmStatus, 'value', 'text'),
      defaultForm: {
        list: {
          search: '',
          brand: [],
          category: [],

          gm_status: [],
          goods_status: 'registered',
          stock_status: 'normal',
          display_status: 'view',
          major_color: this.$C.COLORS.map(e => e.name),
          goods_nm_en: 'ALL',
          sortKey: 'goods_no',
          sortDir: 'desc',

          limit: 100,
          skip: 0,

          fields: {},
          incExc: {},
        }
      },
      form: {
        list: {}
      },
      matching: {
        gm_id: null,
        gm_status_reason: null,
        excludeType: null,
      },
      collapse: {detail: false},
      lastBody: {list: {}},
      items: {list: []},
      total: {list: null}, // list: {value: 0, relation: 'eq'}
      lastSort: {list: null}, // list:  ['', 40434254] // sortKey, uniqueId
      busy: {list: false, confirmMatching: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      title: '',
      // item: {},
      itemAttr: {},
      fields: [
        'selected',
        {key: '_img60', label:'이미지', class: 'text-center'},
        {key: 'html2', label:`상품정보<br/><span class="badge badge-success">판매 가능수량</span><span class="badge badge-primary">SHOP 원본 수량</span><span class="badge badge-info">결제된 주문수량</span>`
              + `<span class="badge badge-warning">10분이내 주문 미결제수량</span>`, style:{minWidth:'250px'}},
        {key: 'html5', label:'<span class="badge badge-light">goods_id</span><br/><span class="badge badge-light">SKU ID</span><br/><span class="badge badge-light">통합 SKU</span>', class: 'text-center'},
        {key: 'html1', label:'<span class="badge badge-light">연동형태</span><br/><span class="badge alert-success">상품유형</span><br/><span class="badge badge-light">중고등급</span>', class: 'text-center'},
        {key: 'html10', label:'배송정보', class: 'text-center', style:{minWidth:'100px'}},
        {key: 'html4', label:'외부몰', class: 'text-center', style:{minWidth:'60px'}},
        {key: 'html3', label:'가격', class: 'text-right'},
        {key: 'html9', label:'발란몰가<br/>SS가', class: 'text-right'},
        {key: 'html6', label:'재고', style:{width:'50px'}, class: 'text-center'},
        {key: 'html8', label:'<span class="badge badge-primary">Naver Rank</span><br/><span class="badge alert-info">최저가 차이</span><br/><span class="badge alert-success">타사 상품수</span>', style:{width:'80px'}, class: 'text-center'},
        {key: 'html7', label:'상태', class: 'text-center'},
        {key:'_actions', label:'상세', style:{width:'55px', textAlign:'center'}, buttons: [{label:'상세', event:'show_modal'}]},
      ],
      itemMode: 'pic',
      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      rotateHandler: null,

      modal: {detail: false, diffList: false, field: false, matchMaster: false, removeMatching: false, excludeMatching: false, rollbackExclude: false},
      editMode: false,
      diff: null,
      priceHtml: '',
      today: momentBiz().format('YYYY-MM-DD'),
      xlsx: {
        keys: [],
        labels: [],
      },
      xlsxItems: [],

      formOptions: [
        [
          {
            name: '등록상태', key: 'goods_status', role: '!SKU_MATCHER', options: [
              {text: '전체', value: 'ALL'},
              {text: 'Processing', value: 'processing', variant: 'info'},
              {text: 'Registered', value: 'registered', variant: 'success'},
              {text: 'Terminated', value: 'terminated', variant: 'danger'}
            ]
          },
          {
            name: '재고상태', key: 'stock_status', role: '!SKU_MATCHER', options: [
              {text: '전체', value: 'ALL'},
              {text: '재고있음', value: 'normal', variant: 'success'},
              {text: '품절', value: 'runout', variant: 'warning'}
            ]
          },
          {
            name: '노출상태', key: 'display_status', role: '!SKU_MATCHER', options: [
              {text: '전체', value: 'ALL'},
              {text: '노출', value: 'view', variant: 'success'},
              {text: '미노출', value: 'notview', variant: 'warning'}
            ]
          },
          {name: '마스터 매칭 상태', type: 'checkbox', key: 'gm_status', options: metaGmStatus},
          {name: '영문이름 여부', key: 'goods_nm_en', options: formOptionsPreset.EXISTS_YN},
        ],
        [
          {key: 'divider'},
          {
            name: '정렬기준', key: 'sortKey', options: [
              {text: '발란코드', value: 'goods_no', variant: 'primary'},
              {text: '통합SKU', value: 'matched_sku_id', variant: 'primary', nullable: true},
              {text: 'GM ID', value: 'gm_id', variant: 'primary', nullable: true},
              {text: '등록일시', value: 'registered_dt', variant: 'info', nullable: true},
              {text: '가격', value: 'price', variant: 'info'},
              {text: '소비자가', value: 'consumer', variant: 'info'},
              {text: '할인율', value: 'discount_rate', variant: 'info'},
              {text: '재고', value: 'tot_stock', variant: 'info'},
              {text: '시즌', value: 'season', variant: 'info', nullable: true},
              {text: '옵션수', value: 'optionCount', variant: 'info'},
              {text: '재고있는옵션수', value: 'optionHasStockCount', variant: 'info'},
            ]
          },
          {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
        ],
      ],
      sortKeyOptionMap: {},
      formFields: [
        {name: '발란코드', key: 'goods_no', type: 'number'},
        {
          name: '발란회원가', key: 'price', type: 'number', rangePreset: [
            {text: '~ 1만원', gte: '', lte: 10000},
            {text: '1 ~ 3만원', gte: 10000, lte: 30000},
            {text: '3 ~ 10만원', gte: 30000, lte: 100000},
            {text: '10 ~ 20만원', gte: 100000, lte: 200000},
            {text: '20 ~ 30만원', gte: 200000, lte: 300000},
            {text: '30 ~ 50만원', gte: 300000, lte: 500000},
            {text: '50 ~ 100만원', gte: 500000, lte: 1000000},
            {text: '100 ~ 300만원', gte: 1000000, lte: 3000000},
            {text: '300 ~ 500만원', gte: 3000000, lte: 5000000},
            {text: '500 ~ 1000만원', gte: 5000000, lte: 10000000},
            {text: '1000만원 ~', gte: 10000000, lte: ''},
          ]
        },
        {name: '소비자가', key: 'consumer', type: 'number'},
        {name: '할인율', key: 'discount_rate', type: 'number', op: 'range'},
        {name: 'Sku ID', key: 'sku_id', type: 'string', width: 150},
        {name: '통합 Sku', key: 'matched_sku_id', type: 'string', width: 150},
        {name: 'Short Sku', key: 'short_sku', type: 'string', width: 150},
        {name: 'B최저가 순위', key: 'b_rank', type: 'number', width: 110},
        {name: 'B최저가 차이(%)', key: 'b_price_diff_per', type: 'number', op: 'range', width: 130},
        {name: '시즌', key: 'season', type: 'string', width: 120},
        {name: '원산지', key: 'origin', type: 'string', width: 120},
        {name: '총 재고', key: 'tot_stock', type: 'number', op: 'range', width: 100},
        {name: '옵션 재고', key: 'options.stock', type: 'number', op: 'range'},
        {name: '옵션 Size', key: 'options.Size', type: 'string', op: 'like', width: 130},
        {name: '옵션수', key: 'optionCount', type: 'number', op: 'range'},
        {name: '재고있는옵션수', key: 'optionHasStockCount', type: 'number', op: 'range', width: 130},
        {name: '상품타입', key: 'goodsType', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
        {name: '상품관리코드', key: 'mng_code', type: 'string', width: 150},
        {name: '오늘출발시각', key: 'today_pick_time', type: 'string', placeholder: '13:00', disableLike: true},
        {name: '생성시각', key: '_cdt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
        {name: '등록시각', key: 'registered_dt', type: 'string', placeholder: '2020-01-01 01:02:03', disableLike: true, width: 155},
      ],
      defaultFields: 'sku_id:eq,matched_sku_id:eq,short_sku:eq,b_rank:eq,_cdt:range',
      customFormFields: [],
      formIncExc: [
        {name: '발란코드', key: 'goods_no', type: 'number'},
        {name: '파트너상품코드', key: 'goods_id', type: 'string'},
        {name: 'gid', key: 'gid', type: 'string'},
        {name: 'GM ID', key: 'gm_id', type: 'string'},
        {name: 'Sku(파트너,통합)', key: 'sku', type: 'string', includePlaceholder: '포함할 SKU(파트너 및 통합 SKU 대상)', excludePlaceholder: '제외할 SKU'},
        {name: 'Sku ID', key: 'sku_id', type: 'string'},
        {name: '통합 Sku', key: 'matched_sku_id', type: 'string'},
        {name: 'Short Sku', key: 'short_sku', type: 'string'},
        {name: '시즌', key: 'season', type: 'string'},
        {name: '원산지', key: 'origin', type: 'string'},
        {name: '상품관리코드', key: 'mng_code', type: 'string'},
        {name: '오늘출발시각', key: 'today_pick_time', type: 'string'},
      ],
      defaultIncExc: 'goods_no,gm_id,matched_sku_id,short_sku',
      customFormIncExc: [],
      validator: {
        'global-upload': {
          '발란코드': {test: /^\d+$/, type: 'number', required: true},
          '상품명 영문': {test: /^[^가-힣].*$/, type: 'string'},
        },
      }
    }

  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'collapse,picWidth');
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));

    this.busy.list = true;
    let meta = await this.$api.getMeta('shop,brand,category,holiday');

    // 공휴일 설정
    let holidays = meta.holiday.map(e => {
      if (e.require) return momentBiz().format('YYYY-') + e.date;
      return e.date;
    });
    // 작년, 내년도 추가한다
    holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
    holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    window.moment = momentBiz;

    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          let v = this.$route.query[k];
          if (~'limit,skip'.split(',').indexOf(k)) {
            this.form.list[k] = +v;
          } else if (k === '$category') {
            let arr = v.split(',');
            this.form.list.category = arr.map(e => this.categoryMap[e]);
          } else if (k === 'collapse.detail') {
            this.collapse.detail = !(!v || v === 'false');
          } else if (~Object.keys(this.form.list).indexOf(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    const no = this.$route.params.no;
    if (no) {
      this.form.list.search = no;
      this.form.list.display_status = 'ALL';
      await this.list();
      let item = this.items.list.filter(e => e.goods_no === +no)[0];
      if (item) this.showModal({item});
      return;
    }
    this.sortKeyOptionMap = this.$utils.arr2map(this.formOptions.flat().find(e => e.key === 'sortKey').options, 'value');
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'collapse,picWidth');
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.list.forEach(e => {
          let uv = v.toUpperCase();
          e.filtered = ~e.goods_nm.toUpperCase().indexOf(uv)
            || ~e.sku_id.toUpperCase().indexOf(uv)
            || ~e.goods_id.toUpperCase().indexOf(uv)
            || ~(e.goods_no + '').toUpperCase().indexOf(uv)
            || ~(e.org_name || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm_kr || '').toUpperCase().indexOf(uv)
            || ~(e.category_nm || '').toUpperCase().indexOf(uv)
        });
        this.picFilteredCnt = this.items.list.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.list.filter(e => e.filtered && (v === 'ALL' || e.selected)).length;
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'picWidth');
    },
  },
  methods: {
    upXlsx(type){
      this.$refs['xlsx-' + type].value = null;
      this.$refs['xlsx-' + type].click();
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      const {headers, rows} = await readXlsx(file);
      const type = event.target.dataset.type;
      if (type === 'global-upload') {
        this.uploadGlobalXlsx(event.target, headers, rows, type);
      }
    },
    async uploadGlobalXlsx(target, headers, rows, type) {
      const vMap = this.validator[type];

      const wrongRows = [];
      rows.forEach((row, i) => {
        const wrongCols = [];
        headers.filter(h => vMap[h]).forEach(h => {
          const tester = vMap[h].test;
          if ((row[h] != null && row[h] !== '') && tester && !tester.test(row[h])) {
            wrongCols.push(`${h}: ${row[h]}`);
          }
          if ((row[h] == null || row[h] === '') && vMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 1} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      const typeHeaders = headers.filter(h => vMap[h] && vMap[h].type);
      rows.forEach(row => {
        typeHeaders.forEach(h => {
          const type = vMap[h].type;
          if (type === 'number' && row[h]) {
            row[h] = +row[h];
          } else if (type === 'string' && row[h]) {
            row[h] = '' + row[h];
          } else if (type === 'boolean' && row[h]) {
            if (row[h] && typeof row[h] !== 'boolean') {
              if (row[h].match(/^true$/i)) {
                row[h] = true;
              } else if (row[h].match(/^false$/i)) {
                row[h] = false;
              } else {
                return alert('TRUE / FALSE 값이 맞게 입력되었는지 확인해주세요: ' + row[h]);
              }
            }
          }
        });
      });

      this.busy.xlsxUp = true;
      const j = await this.$api.postJson('/goods/uploadGlobal', {rows});
      this.busy.xlsxUp = false;
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt} 건 정상적으로 업로드 되었습니다`);
        this.list();
      } else if (j.ok === -1) {
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + `<h4>${j.msg}</h4>` + '</pre>'});
      }
      target.value = '';
    },
    rotateImage($event, e) {
      let i = 0;
      const $img = $event.srcElement;
      this.rotateHandler = setInterval(() => {
        i = (i + 1) % e.img_urls.length;
        $img.src = e.img_urls[i];
      }, 500);
    },
    stopRotate($event, e) {
      clearInterval(this.rotateHandler);
      $event.srcElement.src = e.img_urls[0];
    },
    dragSelectItems(divs) {
      const selectedMap = {};
      this.items.list.forEach(e => selectedMap[e.goods_no] = e.selected);
      divs.map(e => e.getAttribute('attr')).forEach(e => selectedMap[e] = true);
      this.items.list.forEach(e => e.selected = !!selectedMap[e.goods_no]);

      this.$forceUpdate();
    },
    tempLog(e) {
      console.log(this.form.list.gm_status);
    },
    godoPriceLambda(price) {
      return price;
    },
    ssPriceLambda(e) {
      return e;
    },

    async list(more) {
      const form = this.form.list;
      const pattern = /^[\s|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9]*$/gi;
      if (!pattern.test(form.search)) return alert('검색어에는 특수문자가 들어갈 수 없습니다.');

      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const major_color = form.major_color.length === this.$C.COLORS.length ? [] : form.major_color; // 전체 선택일 경우 비우기
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      const body = {
        ...form,
        brand,
        category,
        major_color,
        fields,
        incExc,
        isSortKeyNotNull: !this.sortKeyOptionMap[form.sortKey].nullable
      };
      // more 모드라면 중복을 제거한다.
      if (this.IS_DEV) {
        body.incExc.forEach(incExc => {
            body[`${incExc.key}_include`] = incExc.include
            body[`${incExc.key}_exclude`] = incExc.exclude
        });
        // let body = {...form, brand, category, goods_no, goods_no_exclude, gm_id, gm_id_exclude, matched_sku_id};
        this.$api.postTable(this, '/master/confirmed', body, {more, fnAssign: this.assignTableData});
      } else {
        this.$api.postTable(this, '/goods/confirmed/es', body, {
          more,
          fnAssign: this.assignTableData,
          removeDupId: 'goods_no',
          uniqueId: 'goods_no',
        });
      }
    },
    assignTableData(e) {
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      }
      if (!e.consumer) e.consumer = e.price;

      // 배송유형 및 일자 확정
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad:'해외', domestic:'국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 21;
          }
        }
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_day = 21; // 이전부터 있던 하드코딩, 6일인 이유는?
        e.delivery_str = 'SHOP배송정보없음';
      }
      e.godoPrice = this.godoPriceLambda(e.price, {category: e.category});
      e.ssPrice = this.ssPriceLambda(e.price);

      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];

      // 브랜드속성을 추가해준다.
      // https://www.notion.so/4198820b7eea40a09c6728a155e7d423?pvs=4#4cb38f7ed4114026a5aa8605e1ee034f
      e.brand_type_kr = (this.$C.BRAND_TYPE_MAP[this.brandMap[e.brand_no].brand_type] || {}).name || '';
    },

    showModal(row) {
      this.$refs.masterConfirmedModal.showModal(row);
    },

    showImageModal(item) {
      this.$refs.imageModal.show(item);
    },

    exclude(item) {
      this.form.list.goods_no_exclude += ' ' + item.goods_no;
    },

    showMasterMatchingModal(e) {
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('마스터를 매칭할 상품을 선택해주세요');

      this.matching.gm_id = null;
      this.modal.matchMaster = true;
    },

    async matchMaster(e) {
      const selectedItems = this.items.list.filter(e => e.selected);

      if (selectedItems.filter(e => e.gm_status).length) {
        alert('보류 상태이거나 이미 마스터가 매칭된 상품은 마스터 매칭이 불가합니다')
        e.preventDefault && e.preventDefault();
        return;
      }

      if (!this.matching.gm_id) {
        alert('매칭할 마스터 ID를 입력해 주세요')
        e.preventDefault && e.preventDefault();
        return;
      }

      let j = await this.$api.postJson('/master/match', {
        items: selectedItems.map(e => ({
          goods_no: e.goods_no,
          brand_no: e.brand_no,
          category: e.category,
        })),
        gm_id: this.matching.gm_id.trim(),
      });
      if (j) {
        const wrongs = new Set(j.wrongBrandGoodsnoList);
        // const lastOnes = new Set(j.lastOneGoodsnoList);
        let alertMsg = `<strong>전체: ${selectedItems.length}, 임시 매칭 성공: ${selectedItems.length - wrongs.size}, 매칭 보류: ${wrongs.size}</strong><br/>`;
        if (wrongs.size) {
          alertMsg += `<br/>브랜드 오류로 매칭 보류된 상품:<br/>` + [...wrongs].join(',');
        }
        // if (lastOnes.size) {
        //   alertMsg += `<br/>LAST ONE으로 매칭 보류된 상품:<br/>` + [...lastOnes].join(',');
        // }
        this.$utils.alert(alertMsg);

        // 임시 매칭 트래킹
        this.$api.sendBeacon('/master/track/tempMatching', {
          goodsnoList: selectedItems.filter(e => !wrongs.has(e.goods_no)).map(e => e.goods_no),
          gm_id: this.matching.gm_id,
        });
        this.list();
      }
    },

    async confirmMatching(e) {
      if (this.busy.confirmMatching) return alert('요청이 진행중입니다. 잠시 후 다시 시도해 주세요');
      const selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('매칭을 확정할 상품을 선택해주세요');

      // 임시매칭 상태일 때만 확정매칭이 가능하다.
      if (selectedItems.some(e => e.gm_status !== 'temp_matched')) return alert('임시매칭 상태의 상품만 매칭을 확정할 수 있습니다');

      if (!confirm(`총 ${selectedItems.length} 건의 매칭을 확정하시겠습니까?`)) return;
      this.busy.confirmMatching = true;
      let j = await this.$api.postJson('/master/confirm', {
        goodsnoList: selectedItems.map(e => e.goods_no),
      });
      if (j) {
        this.$alertTop('매칭 확정이 완료되었습니다', {timeout: 3000});
        // 확정 매칭 트래킹
        this.$api.sendBeacon('/master/track/confirmMatching', {
          matchedPairList: selectedItems.map(e => ({
            goodsno: e.goods_no,
            gm_id: e.gm_id,
          })),
        });
        this.list();
      }
      this.busy.confirmMatching = false;
    },

    showRemoveMatchingModal(e) {
      const selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('매칭을 해제할 상품을 선택해주세요');

      this.matching.gm_status_reason = null;
      this.modal.removeMatching = true;
    },

    async removeMatching(e) {
      const selectedItems = this.items.list.filter(e => e.selected);

      // 임시매칭이거나 확정매칭일 때만 매칭 해제가 가능하다.
      if (selectedItems.some(e => !['temp_matched', 'confirmed'].includes(e.gm_status))) {
        alert('매칭 상태의 상품만 매칭을 해제할 수 있습니다');
        e.preventDefault && e.preventDefault();
        return;
      }

      // 매칭 해제 사유를 입력해야 한다.
      if (!this.matching.gm_status_reason) {
        alert('매칭 해제 사유를 입력해 주세요');
        e.preventDefault && e.preventDefault();
        return;
      }

      let j = await this.$api.postJson('/master/removeMatching', {
        goodsnoList: selectedItems.map(e => e.goods_no),
        reason: this.matching.gm_status_reason,
      });
      if (j) {
        this.$alertTop('매칭 해제가 완료되었습니다', {timeout: 3000});
        this.list();
      }
    },

    showExcludeMatchingModal(e) {
      const selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('매칭 보류 처리할 상품을 선택해주세요');

      this.matching.gm_status_reason = null;
      this.matching.excludeType = null;
      this.modal.excludeMatching = true;
    },

    async excludeMatching(e) {
      const selectedItems = this.items.list.filter(e => e.selected);

      // 매칭 상태가 아닐 때만 보류 처리가 가능하다.
      if (selectedItems.some(e => ['temp_matched', 'confirmed'].includes(e.gm_status))) {
        alert('이미 마스터가 매칭된 상품은 보류 처리할 수 없습니다');
        e.preventDefault && e.preventDefault();
        return;
      }

      // 보류 유형을 선택해야 한다.
      if (!this.matching.excludeType) {
        alert('보류 유형을 선택해 주세요');
        e.preventDefault && e.preventDefault();
        return;
      }

      // 보류 처리 사유를 입력해야 한다.
      if (!this.matching.gm_status_reason) {
        alert('보류 처리 사유를 입력해 주세요');
        e.preventDefault && e.preventDefault();
        return;
      }

      let j = await this.$api.postJson('/master/excludeMatching', {
        goodsnoList: selectedItems.map(e => e.goods_no),
        reason: this.matching.gm_status_reason,
        excludeType: this.matching.excludeType,
      });
      if (j) {
        this.$alertTop('보류 처리가 완료되었습니다', {timeout: 3000});
        // 임시 매칭 트래킹
        this.$api.sendBeacon('/master/track/excludeMatching', {
          goodsnoList: selectedItems.map(e => e.goods_no),
        });
        this.list();
      }
    },

    showRollbackExcludeModal(e) {
      const selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('보류 해제할 상품을 선택해주세요');

      this.matching.gm_status_reason = null;
      this.modal.rollbackExclude = true;
    },

    async rollbackExclude(e) {
      const selectedItems = this.items.list.filter(e => e.selected);

      // 보류 상태일 때만 보류 해제가 가능하다.
      if (selectedItems.some(e => !(e.gm_status || '').startsWith('excl'))) {
        alert('현재 매칭 보류 상태가 아닌 상품은 보류 해제할 수 없습니다');
        e.preventDefault && e.preventDefault();
        return;
      }

      // 보류 해제 사유를 입력해야 한다.
      if (!this.matching.gm_status_reason) {
        alert('보류 해제 사유를 입력해 주세요');
        e.preventDefault && e.preventDefault();
        return;
      }

      let j = await this.$api.postJson('/master/rollbackExclude', {
        goodsnoList: selectedItems.map(e => e.goods_no),
        reason: this.matching.gm_status_reason,
      });
      if (j) {
        this.$alertTop('보류 해제가 완료되었습니다', {timeout: 3000});
        this.list();
      }
    },

    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },

    clickItem(e, item) {
      item.selected = !item.selected;
      this.$forceUpdate();
    },
    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },
    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.filter(e => e[col]).map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
    copyOne(content) {
      if (this.$utils.copyToClipboard(content)) this.$alertTop(`복사되었습니다`);
    },

    scrollTo(ref) {
      const y = this.$refs[ref].offsetTop - this.$refs[ref].clientHeight - 20;
      window.scroll({
        top: y,
        left: 0,
        behavior: 'smooth',
      })
    },
    preDown(type){
      let name = '';
      let baseFields = {};

      if (type === 'global-upload') {
        name = `MasterMatching_global_${this.$utils.dt()}.xlsx`;

        this.xlsxItems = this.items.list.filter(e => e.selected).map(e => this.$utils.clone(e));
        if (!this.xlsxItems.length) return alert('다운받을 상품을 선택해주세요');
        const GLOABL_UPLOAD_COLUMS = {
          goods_no: '발란코드',
          goods_nm_en: '상품명 영문'
        }
        Object.entries(GLOABL_UPLOAD_COLUMS).forEach(([k,v])=>{
          if (v.use !== false) baseFields[k] = v;
        });
      } else {
        name = `MasterMatching_${this.$utils.dt()}.xlsx`;

        this.xlsxItems = this.items.list.filter(e => e.selected).map(e => this.$utils.clone(e));
        if (!this.xlsxItems.length) return alert('다운받을 상품을 선택해주세요');
        let baseFields = {};
        Object.entries(MASTER_MATCHING_COLUMNS).forEach(([k,v])=>{
          if (v.use !== false) baseFields[k] = v.name;
          // 브랜드속성 추가
          // https://www.notion.so/4198820b7eea40a09c6728a155e7d423?pvs=4#4cb38f7ed4114026a5aa8605e1ee034f
          if (k === 'brand_nm_kr') {
            baseFields['brand_type_kr'] = '브랜드속성';
          }
        });

        for (const si of this.xlsxItems) {

          let gs = si.gm_status;
          const result = this.metaGmStatus.find(item => item.value === gs);
          si.gm_status = result ? result.text : '매칭 대상';

          const categoryPath = si.cate.split('>').map(e => e.trim());
          si.gender = categoryPath[0]? categoryPath[0]: '';
          si.first = categoryPath[1]? categoryPath[1]: '';
          si.second = categoryPath[2]? categoryPath[2]: '';
          si.third = categoryPath[3]? categoryPath[3]: '';

        }
      }

      let fields = Object.keys(baseFields).join(',');
      let headers = Object.values(baseFields).join(',');

      this.$refs.json_data.value = JSON.stringify({data: this.xlsxItems, keys: fields.split(','), labels: headers.split(','), type: 'xlsx', name});
      this.$refs.xlsx_form.submit();
    },

    resetForm() {
      const fields = this.form.list.fields;
      const incExc = this.form.list.incExc;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      Vue.set(this.form.list, 'incExc', incExc);

      // this.$refs.fields.resetFieldValues();
      // this.$refs.incExc.resetValues();
    }
  }
}
</script>

<style>
.cf_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.cf_desc {word-break: break-word;}
.cf_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.cf_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc {word-break: break-word;}
.mp_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

</style>
